import React from 'react';

import Layout from '../components/Layout';

import mobileAppPerson from '../assets/images/mobile-app-person.png';
import webapp from '../assets/images/web-app.png';
import server from '../assets/images/server-screen.png';
import monitor from '../assets/images/monitor.png';
import apiScreens from '../assets/images/api-screens.png';
import chooses from '../assets/images/chooses.png';


import config from '../../config';
const IndexPage = () => (
    <Layout>
        <section id="banner">
            <div className="inner">
                <div className="logo"><span className="icon fa-code"></span></div>
                <h2>{config.heading}</h2>
                <p>{config.subHeading}<br />{config.subHeading2}</p>
            </div>
        </section>

        <section id="wrapper">
            <section id="one" className="wrapper spotlight style1">
                <div className="inner">
                    <a href="/#" className="image">
                        <img src={webapp} alt="" />
                    </a>
                    <div className="content">
                        <h2 className="major">Mobile Applications & Websites</h2>
                        <p>
                            Need a developer to bring your vision to life?
                            All apps I create deliver an exceptional user experience through powerful features: intuitive functionality, and beautiful design.
                             {/* Or more?  */}
                            {/* I can handle that! Need more? I can handle that too. */}
                            {' '} My range of services include: design, engineering, testing, publishing, and consultation.
                        </p>
                    </div>
                </div>
            </section>

            <section id="two" className="wrapper alt spotlight style2">
                <div className="inner">
                    <a href="/#" className="image">
                        <img src={server} alt="" />
                    </a>
                    <div className="content">
                        <h2 className="major">Server-Side</h2>
                        <p>
                            Websites and apps need quality server-side services to function at a high level.
                            I view quality of code and scalablity of systems as the foundation to any server-side project.
                            I have the experience to handle sophisticated business logic, large data sets, and complex access requirements.
                    </p>
                    </div>
                </div>
            </section>

            <section id="three" className="wrapper spotlight style3">
                <div className="inner">
                    <a href="/#" className="image">
                        <img src={chooses} alt="" />
                    </a>
                    <div className="content">
                        <h2 className="major">Project Management</h2>
                        <p>
                            A project needs more than just code to come alive.
                            I'm a developer with a background in project management, so I have the technical and business background to effectivly launch projects.
                            Interface across departments, gather specifications, set milestones, align resources, and manage the execution, of course!
                            

                            
                            {/* Trust and communication are central to any partnership.
                            I'll always keep you in the loop on how your project is progresing and any key decisions that need to be made.
                            I don't recommend products or solutions that you don't need! */}
                        </p>
                    </div>
                </div>
            </section>


            {/* 
            
                TODO: convert this unordered list to the same ui comps as the skills section
                Then add a giant process circle arrow over the 4 sections (desktop/tablet... what should this look like for mobile? maybe just ahve the icon appear in the header)
                another idea: add arrows thatpoint to the next step (discovery right arrow point to design)
            
            */}

            


            <section id="two" className="wrapper alt style3">
                <div className="inner">
                    <h3 className="major" style={{ fontSize: 36 }}>My Process</h3>
                    <p style={{ marginBottom: 100 }}>
                        {/* Cras mattis ante fermentum, malesuada neque vitae, eleifend erat.
                        Phasellus non pulvinar erat. Fusce tincidunt, nisl eget mattis
                        egestas, purus ipsum consequat orci, sit amet lobortis lorem lacus
                        in tellus. Sed ac elementum arcu. Quisque placerat auctor laoreet. */}
                    </p>
                    <ul className="icons contact">
                        <li className="icon fa-globe">
                            <h3>Discovery</h3>
                            <p>
                                Outline high level objectives, technical requirements, and user experience for the project.
                            </p>
                        </li>
                        <li className="icon solid fa-pencil">
                            <h3>Design</h3>
                            <p>
                                Identify and select the technologies that will be utilized to build the project. Create and finalize wireframes, mockups, and user flows.
                            </p>
                        </li>
                        <li className="icon solid fa-code">
                            <h3>Development</h3>
                            <p>
                                Code, review, aquire feedback, and modify. Repeating this process until everything is just right!
                            </p>
                        </li>
                        <li className="icon solid fa-rocket">
                            <h3>Launch</h3>
                            <p>
                                Houston, We Have Lift Off! Your product is released and ready for your customers to enjoy!
                            </p>
                        </li>
                    </ul>
                </div>
            </section>


            <section id="four" className="wrapper alt style1">
                <div className="inner">
                    <h3 className="major" style={{ fontSize: 36 }}>Skills</h3>
                    <p>
                        {/* Cras mattis ante fermentum, malesuada neque vitae, eleifend erat.
                        Phasellus non pulvinar erat. Fusce tincidunt, nisl eget mattis
                        egestas, purus ipsum consequat orci, sit amet lobortis lorem lacus
                        in tellus. Sed ac elementum arcu. Quisque placerat auctor laoreet. */}
                    </p>
                    <section className="features">
                        {config.skillSections.map((section, index) =>
                            <article key={index}>
                                <h2 className="major">{section.title}</h2>
                                <ul>
                                    {section.skills.map((skill, ind) =>
                                        <li key={ind}>
                                            {skill}
                                        </li>
                                    )}
                                </ul>
                            </article>
                        )}
                    </section>
                </div>
            </section>
        </section> {/* end section wrapper */}
    </Layout>
);

export default IndexPage;
















/* 
 <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={mobileAppPerson} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Mobile Application Development</h2>
            <p>
              My range of mobile app services include design, engineering, testing, publishing, and consultation.
              All the mobile apps I build deliver an exceptional user experience through powerful features, intuitive functionality, and beautiful design.
            </p>
       
            </div>
            </div>
          </section>
    
          <section id="two" className="wrapper alt spotlight style2">
            <div className="inner">
              <a href="/#" className="image">
                <img src={monitor} alt="" />
              </a>
              <div className="content">
                <h2 className="major">Web Development</h2>
                <p>
                  From landing pages to content management systems -- I have over 7 years of experience building solutions that have complex business logic, large data sets, and high end.
                  I have the experience to build everything from landing pages to content management systems.
                  solutions that have complex business logic, large data sets, and high end.
                </p>
     
              </div>
            </div>
          </section>
*/